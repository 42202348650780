import React from 'react';

import MuiButton from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import LinkWrapper from './LinkWrapper';

const Button = ({
  text,
  link = false,
  externalLink = false,
  variant = "contained",
  color = "primary",
  marginLeft = 0,
  marginRight = 0,
  marginTop = 0,
  marginBottom = 0,
  paddingLeft = '24px',
  paddingRight = '24px',
  fontSize = "14px",
  width = "auto",
  slim = false,
  onClick = false,
}) => {

  const button = (
    <MuiButton 
      variant={variant}
      color={color}
      sx={{
        borderRadius: 4,
        marginLeft,
        marginRight,
        marginTop,
        marginBottom,
        width,
        ...(slim ? { height: '36px' } : {})
      }}
      onClick={() => onClick && onClick()}
    >
      <Typography style={{fontWeight: 500,  lineHeight: '35px', fontSize, letterSpacing: '0.06rem', paddingLeft, paddingRight}}>
        {text}
      </Typography>
    </MuiButton>
  )

  return (
    <>
      {
        link ? 
          <LinkWrapper
            link={link}
            external={externalLink}
          >
            {button}
          </LinkWrapper>
        : button
      }
    </>
  )
}

export default Button;