import React from 'react';

import { Link } from 'gatsby-theme-material-ui';

import ExternalLinkIcon from '@mui/icons-material/Launch';

import ExternalLink from './ExternalLink';

import {
  trackCustomEvent,
} from '../utils';

// interface ILinkWrapper {
//   link?: string,
//   external?: boolean,
//   className?: string,
//   children: React.ReactNode,
//   onClick?: () => void,
//   showExternalLinkIcon?: boolean,
//   style?: {[key: string]: string},
// }

// we use this component to dynamically handle internal links and external links

const LinkWrapper = (props) => {
  const {
    id,
    link,
    external = false,
    fullWidth = false,
    className,
    children,
    showExternalLinkIcon = false,
    style = {},
    noFlex = false,
  } = props;

  const trackLinkClick = () => {
    trackCustomEvent({
      category: 'crypto_escrow_links',
      action: `${external ? "external" : "internal"}_link_clicked_${link}`,
      label: `${id ? id : 'NO_ID'}`,
      value: link ? link : 'NO_LINK',
    })
  }

  if(external && link) {
    return (
      <ExternalLink onClick={() => trackLinkClick()} fullWidth={fullWidth} className={[className, 'no-decorate', 'inherit-color'].join(" ")} style={{...(!noFlex && {display: 'flex', alignItems: 'center'}), ...style, ...(fullWidth && {width: '100%'})}} href={link}>
        {children}
        {showExternalLinkIcon &&
          <ExternalLinkIcon style={{marginLeft: 4}} />
        }
      </ExternalLink>
    )
  } else if(!external && link) {
    let useLink = `/${link}`.replace('//', '/');
    return (
      <Link onClick={() => trackLinkClick()} style={style} className={[className, 'no-decorate', 'inherit-color'].join(" ")} to={useLink}>
        {children}
      </Link>
    )
  }
  return (
    <>
      {children}
    </>
  );
}

export default LinkWrapper;