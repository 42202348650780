import ReactDOM from 'react-dom/client'

import './src/index.css';

export function replaceHydrateFunction() {
  return (element, container) => {
    const root = ReactDOM.createRoot(container)
    root.render(element)
  }
}

export const onInitialClientRender = () => {
  requestAnimationFrame(() => {
    document.body.classList.add('loaded')
  })
}

export const onClientEntry = () => {
  // We rather do this stuff in WindowStateManager to respect cookie consent
  // if (typeof window !== 'undefined' && (["production", "stage"].indexOf(process.env.GATSBY_ENV) > -1)) {
  //   // Create script element
  //   const script = document.createElement('script')
  //   script.innerHTML = `
  //     window.smartlook||(function(d) {
  //   var o=smartlook=function(){ o.api.push(arguments)},h=d.getElementsByTagName('head')[0];
  //   var c=d.createElement('script');o.api=new Array();c.async=true;c.type='text/javascript';
  //   c.charset='utf-8';c.src='https://web-sdk.smartlook.com/recorder.js';h.appendChild(c);
  //   })(document);
  //   smartlook('init', '6bb5a0bdab863c838a4ecf0000364eec693fa91e', { region: 'eu' });
  //   `
    
  //   // Add script to head
  //   document.head.appendChild(script)
  // }
  // if(typeof window !== 'undefined') {
  //   // Initially add a class to prevent FOUC
  //   document.body.classList.add('loading')
    
  //   window.addEventListener('load', () => {
  //     document.body.classList.remove('loading')
  //     document.body.classList.add('loaded')
  //   })
  // }
}