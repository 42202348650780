import { useEffect, useState } from "react";
import ReactGA from 'react-ga4';

import { APP_CONFIG } from '../utils';

let isGAInitialized = false;

const usePageTracking = ({location}) => {
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (!isGAInitialized) {
      ReactGA.initialize(APP_CONFIG.GOOGLE_ANALYTICS_ID);
      isGAInitialized = true;
      setInitialized(true);
    }
  }, []);

  useEffect(() => {
    if (initialized) {
      document.documentElement.scrollTo(0, 0);
      ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
    }
  }, [location, initialized]);

};

export default usePageTracking;