import { useEffect, useCallback, useState } from 'react';

const persistentParameterKeys = [
  'start', // ref coinbase crypto escrow
  'booking_complete' // ref for booking process completed (hubspot redirect)
];

const useQueryParam = () => {
  const [searchParams, setSearchParams] = useState(null);

  useEffect(() => {
    // This will only run in the browser, after the component mounts
    setSearchParams(new URLSearchParams(window.location.search));
  }, []);

  useEffect(() => {
    if (searchParams) {
      persistentParameterKeys.forEach(key => {
        const value = searchParams.get(key);
        if (value) {
          try {
            localStorage.setItem(key, value);
          } catch (e) {
            console.warn('localStorage is not available:', e);
          }
        }
      });
    }
  }, [searchParams]);

  const getPersistentQueryParamData = useCallback((key) => {
    let value = null;

    // If not in localStorage and searchParams is available, check URL
    if (!value && searchParams) {
      value = searchParams.get(key);
      
      // If found in URL, try to save to localStorage
      if (value) {
        try {
          localStorage.setItem(key, value);
        } catch (e) {
          console.warn('localStorage is not available:', e);
        }
      }
    }

    // Try localStorage next
    try {
      if(!value && typeof window !== 'undefined' && window.localStorage) {
        value = localStorage.getItem(key);
      }
    } catch (e) {
      console.warn('localStorage is not available:', e);
    }
    
    return value;
  }, [searchParams]);

  const getTransientQueryParamData = useCallback((key) => {
    let value = null;

    // If not in localStorage and searchParams is available, check URL
    if (!value && searchParams) {
      value = searchParams.get(key);
    }

    // Try localStorage next
    try {
      if(!value && typeof window !== 'undefined' && window.localStorage) {
        value = localStorage.getItem(key);
      }
    } catch (e) {
      console.warn('localStorage is not available:', e);
    }
    
    return value;
  }, [searchParams]);

  return { getPersistentQueryParamData, getTransientQueryParamData };
};

export default useQueryParam;