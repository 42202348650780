import React, { useEffect, useLayoutEffect } from 'react';

// import { useIntercom } from 'react-use-intercom';

import useWindowSize from '../hooks/useWindowSize';
import usePageTracking from '../hooks/usePageTracking';

import {
  APP_CONFIG,
} from '../utils';

const WindowStateManager = (props) => {

  let {
    setConsideredMobile,
    setConsideredMedium,
    location,
    consentToCookies,
  } = props;

  // const {
  //   boot,
  //   // shutdown,
  //   // hide,
  //   // show,
  //   // update
  // } = useIntercom();

  // useEffect(() => {
  //   boot();
  // }, [boot])

  usePageTracking(location);

  useEffect(() => {
    if(!consentToCookies) {
      try {
        //@ts-ignore
        if(!window.document.__defineGetter__) {
          Object.defineProperty(document, 'cookie', {
              get: function(){return ''},
              set: function(){return true},
          });
        } else {
          //@ts-ignore
          window.document.__defineGetter__("cookie", function() { return '';} );
          //@ts-ignore
          window.document.__defineSetter__("cookie", function() {} );
        }
      } catch (e) {
        console.error("Error encountered when attempting to block cookie functionality", e);
      }
    } else if (APP_CONFIG.SMARTLOOK_ID) {
      const script = document.createElement('script')
      script.innerHTML = `
        window.smartlook||(function(d) {
      var o=smartlook=function(){ o.api.push(arguments)},h=d.getElementsByTagName('head')[0];
      var c=d.createElement('script');o.api=new Array();c.async=true;c.type='text/javascript';
      c.charset='utf-8';c.src='https://web-sdk.smartlook.com/recorder.js';h.appendChild(c);
      })(document);
      smartlook('init', '${APP_CONFIG.SMARTLOOK_ID}', { region: 'eu' });
      `
      document.head.appendChild(script)
      // const script2 = document.createElement('script');
      // script.type = 'text/javascript';
      // script.id = 'hs-script-loader';
      // script.async = true;
      // script.defer = true;
      // script.src = '//js.hs-scripts.com/4204561.js';

      // document.head.appendChild(script);

      // Cleanup function to remove the script when the component unmounts or when consentToCookies changes to false
      return () => {
        document.head.removeChild(script);
      };
    }
  }, [consentToCookies])

  const windowSize = useWindowSize();

  useLayoutEffect(() => {
    let sizeConsiderMobile = 1100;
    let sizeConsideredMedium = 1535;
    if (windowSize.width && (windowSize.width <= sizeConsiderMobile)) {
      setConsideredMobile(true);
    } else if (windowSize.width) {
      setConsideredMobile(false);
    }
    if (windowSize.width && (windowSize.width <= sizeConsideredMedium)) {
      setConsideredMedium(true);
    } else if (windowSize.width) {
      setConsideredMedium(false);
    }
  }, [windowSize.width, windowSize.height, setConsideredMobile, setConsideredMedium])

  return (
    <></>
  );
}

export default WindowStateManager