export const setShowLeftMenu = (visible: boolean) => ({
    type: "SHOW_LEFT_MENU",
    visible
})

export const setActiveAccount = (account: string) => ({
    type: "SET_ACTIVE_ACCOUNT",
    account
})

export const setDarkMode = (active: boolean) => ({
    type: "SET_DARK_MODE",
    active
})

export const setConsideredMobile = (mobile: boolean) => ({
    type: "IS_CONSIDERED_MOBILE",
    mobile
})

export const setConsideredMedium = (medium: boolean) => ({
    type: "IS_CONSIDERED_MEDIUM",
    medium
})

export const setActiveNetwork = (network) => ({
    type: "SET_ACTIVE_NETWORK",
    network: network,
})

export const setCryptoEscrowFlowBasicInfo = (basicInfo) => ({
    type: "SET_CRYPTO_ESCROW_FLOW_BASIC_INFO",
    firstName: basicInfo.firstName,
    lastName: basicInfo.lastName,
    emailAddress: basicInfo.emailAddress,
})

export const setCryptoEscrowFlowFirstName = (firstName) => ({
    type: "SET_CRYPTO_ESCROW_FLOW_FIRST_NAME",
    firstName,
})

export const setCryptoEscrowFlowLastName = (lastName) => ({
    type: "SET_CRYPTO_ESCROW_FLOW_LAST_NAME",
    lastName,
})

export const setCryptoEscrowFlowEmailAddress = (emailAddress) => ({
    type: "SET_CRYPTO_ESCROW_FLOW_EMAIL_ADDRESS",
    emailAddress,
})

export const setCryptoEscrowFlowSessionId = (sessionId: string) => ({
    type: "SET_CRYPTO_ESCROW_FLOW_SESSION_ID",
    sessionId
})

export const setBtcPrice = (price) => ({
    type: "SET_BTC_PRICE",
    price
})

export const setCoinbaseEscrowSlideshowIndex = (index) => ({
    type: "SET_COINBASE_ESCROW_SLIDESHOW_INDEX",
    index
})

export const setCoinbaseEscrowShowListingZone = (show) => ({
    type: "SET_COINBASE_ESCROW_SHOW_LISTING_ZONE",
    show
})

export const setCoinbaseEscrowFlowVersion = (version) => ({
    type: "SET_COINBASE_ESCROW_FLOW_VERSION",
    version
})

export const giveConsentToCookies = () => ({
    type: "GIVE_CONSENT_TO_COOKIES",
    consent: true,
})

export const setSignedIn = (signedIn) => ({
    type: "SET_SIGNED_IN",
    signedIn
})

export const setHeroVisible = (visible) => ({
    type: "SET_HERO_VISIBLE",
    visible
})