import { connect } from 'react-redux';

import {
  giveConsentToCookies,
} from '../state/actions';

import CookieConsentBanner from '../components/CookieConsentBanner';
  
const mapStateToProps = (state) => ({
  darkMode: state.darkMode,
  isConsideredMobile: state.isConsideredMobile,
  isConsideredMedium: state.isConsideredMedium,
})

const mapDispatchToProps = {
  giveConsentToCookies,
}
  
const connector = connect(mapStateToProps, mapDispatchToProps)

export default connector(CookieConsentBanner)