import { createTheme } from '@mui/material';

export const fonts = {
  robotoMono: 'Roboto Mono Variable',
  spaceGrotesk: 'Space Grotesk Variable',
  robotoSerif: 'Roboto Serif Variable',
}

// A custom theme for this app
const theme = createTheme({
  typography: {
    h4: {
      fontWeight: 500,
      fontSize: '40px',
      lineHeight: '43px',
    },
    h5: {
      fontWeight: 300,
      fontSize: '28px',
      lineHeight: '34px',
    },
    fontFamily: `"Roboto Mono", "Space Grotesk", "Roboto Serif", "Helvetica", "Arial", sans-serif`,
    robotoMono: {
      fontFamily: fonts.robotoMono
    },
    spaceGrotesk: {
      fontFamily: fonts.spaceGrotesk
    },
    robotoSerif: {
      fontFamily: fonts.robotoSerif
    }
  },
  components: {
    MuiCardContent :{
      styleOverrides: {
        root:{
          borderRadius: 15,
          overflow: 'auto',
          "&:last-child": {
            paddingBottom: '16px'
          },
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          // textTransform: 'none',
          fontSize: '1rem',
          boxShadow: 'none',
          borderRadius: '4px!important',
          height: '44px',
          "&:hover": {
            boxShadow: 'none',
          },
        },
        contained: {
          color: 'white',
        },
        containedPrimary: {
          backgroundColor: '#38A6FB',
          color: 'white',
        },
        containedSecondary: {
          backgroundColor: '#FFFFFF',
          color: '#38A6FB',
          '&:hover': {
            backgroundColor: '#b2b2b2',
            color: '#FFFFFF',
          },
        },
      }
    },
    MuiFab: {
      styleOverrides: {
        root: {
          // textTransform: 'none',
          fontSize: '1rem',
          fontWeight: 600,
        },
      }
    }
  },
  palette: {
    mode: 'light',
    primary: {
      main: "#38A6FB",
    },
    action: {
      main: "#38A6FA",
      light: "#4fb3ff",
      dark: "#4fb3ff",
    },
    secondary: {
      main: "#FFFFFF",
    },
    background: {
      default: `#fff`,
    },
    info: {
      main: `#eef0f3`
    }
  },
});

export default theme;
