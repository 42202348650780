import React from 'react';

import { GTMProvider } from '@elgorditosalsero/react-gtm-hook';

import CookieConsentBannerContainer from '../containers/CookieConsentBannerContainer';

import {
  APP_CONFIG,
} from '../utils';

const AnalyticsWrapper = (props) => {

  const gtmParams = {
    id: APP_CONFIG.GOOGLE_GTAG_ID
  };

  const {
    children,
    consentToCookies,
  } = props;

  if(consentToCookies) {
    return (
      <GTMProvider state={gtmParams}>
        {children}
      </GTMProvider>
    )
  } else {
    return (
      <>
        {children}
        <CookieConsentBannerContainer />
      </>
    )
  }
}

export default AnalyticsWrapper;