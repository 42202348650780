import { connect } from 'react-redux';

import AnalyticsWrapper from '../components/AnalyticsWrapper';

const mapStateToProps = (state) => ({
  consentToCookies: state.consentToCookies,
})
  
const mapDispatchToProps = {}
  
const connector = connect(mapStateToProps, mapDispatchToProps)

export default connector(AnalyticsWrapper);