import React from 'react';
import { Location } from '@reach/router'
import { Provider } from 'react-redux';
// import styled from 'styled-components';

// import { IntercomProvider } from 'react-use-intercom';

import { PersistGate } from 'redux-persist/es/integration/react'

import { Toaster } from 'sonner'

import ThemeTopLayout from 'gatsby-theme-material-ui-top-layout/src/components/top-layout';

import store, { persistor } from '../../state';

// import NavigationTopBarContainer from '../../containers/NavigationTopBarContainer';
import WindowStateManagerContainer from '../../containers/WindowStateManagerContainer';
import AnalyticsWrapperContainer from '../../containers/AnalyticsWrapperContainer';
// import DappProviderWagmi from '../../components/DappProviderWagmi';
// import Web3ModalButton from '../../components/Web3ModalButton';

import useReferralLink from '../../hooks/useQueryParam';

import { ThemeProvider as StyledComponentsThemeProvider } from 'styled-components';

import '../../index.css';

import '@fontsource-variable/space-grotesk';
import '@fontsource-variable/roboto-serif';
import '@fontsource-variable/roboto-mono';

// const INTERCOM_APP_ID = 'zpladqap';

// const queryClient = new QueryClient()

// const TopBar = styled.div`
//   width: 100%;
//   display: flex;
//   align-items: center;
//   justify-content: end;
//   margin-top: 8px;
//   margin-bottom: 8px;
// `

export default function TopLayout({ children, theme }) {

  useReferralLink();

  const content = (
    <ThemeTopLayout theme={theme}>
      <StyledComponentsThemeProvider theme={theme}>
        <AnalyticsWrapperContainer>
          <Toaster richColors position="bottom-right" />
          {/* <Location>
            {({ location }) => <NavigationTopBarContainer location={location} />}
          </Location> */}
          <Location>
            {({ location }) => <WindowStateManagerContainer location={location} />}
          </Location>
          {/* <TopBar>
            <Web3ModalButton />
          </TopBar> */}
          {children}
        </AnalyticsWrapperContainer>
      </StyledComponentsThemeProvider>
    </ThemeTopLayout>
  );

  const wrapWithPersistGate = typeof window !== 'undefined' && window.localStorage;

  return (
    // <IntercomProvider appId={INTERCOM_APP_ID}>
      // <QueryClientProvider client={queryClient}>
        // <DappProviderWagmi>
          <Provider store={store}>
            {wrapWithPersistGate ? (
              <PersistGate loading={null} persistor={persistor}>
                {content}
              </PersistGate>
            ) : (
              content
            )}
          </Provider>
        // </DappProviderWagmi>
      // </QueryClientProvider>
    // </IntercomProvider>
  );
}
