import React from 'react';

import { animated, useSpring } from '@react-spring/web';

import Typography from '@mui/material/Typography';

import { fonts } from '../gatsby-theme-material-ui-top-layout/theme';

import LinkWrapper from './LinkWrapper';
import Button from './Button';

function CookieConsentBanner(props) {

    const {
      isConsideredMobile,
      isConsideredMedium,
      giveConsentToCookies,
    } = props;

    const bannerSpring = useSpring({
      from: {
        bottom: 'calc(16px - 100%)',
      },
      to: {
        bottom: isConsideredMedium ? 'calc(16px - 0%)' : 'calc(24px - 0%)',
      },
      delay: 2000,
    })

    const emitConsent = () => {
      giveConsentToCookies();
    }

    return (
      <div style={{ position: 'relative', width: '100%' }}>
        <animated.div 
          style={{
            ...bannerSpring,
            ...{
              padding: '12px',
              position: 'fixed',
              borderRadius: '8px',
              left: '50%',
              transform: 'translateX(-50%)',
              width: 'calc(100% - 30px)',
              maxWidth: '1350px',
              backgroundColor: '#000000e6',
              border: '1px solid grey',
              color: 'white',
              textAlign: 'center',
              boxShadow: '0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14)',
              zIndex: 10,
              ...(!isConsideredMedium && {
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              })
            }
          }}
        >
          <Typography sx={{ fontFamily: fonts.robotoMono, fontSize: '12px', letterSpacing: '-0.01em', fontWeight: 300, ...(isConsideredMedium ? {} : { width: 'calc(100% - 160px)' }) }}>
            We use cookies to personalize content and user experience, provide social media features and to analyze our traffic. For these reasons, we also share information about your use of our site with our partners. <LinkWrapper actionNameGA='privacy_policy_from_cookie_consent_banner' link="https://propy.com/browse/privacy-policy/" external={true} noFlex={true}>Learn more here.</LinkWrapper> By continuing to browse our website, you agree to our use of cookies.
          </Typography>
          <Button
            onClick={() => emitConsent()}
            text="I accept"
            color="secondary"
            variant="outlined"
            marginTop={(isConsideredMobile || isConsideredMedium) ? '8px' : '0px'}
            width={isConsideredMobile ? "100%" : "auto"}
            slim={true}
          />
        </animated.div>
      </div>
    )
};

export default CookieConsentBanner;