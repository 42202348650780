import {combineReducers} from 'redux';
import showLeftMenu from './showLeftMenu';
import activeAccount from './activeAccount';
import darkMode from './darkMode';
import isConsideredMobile from './isConsideredMobile';
import isConsideredMedium from './isConsideredMedium';
import activeNetwork from './activeNetwork';
import btcPrice from './btcPrice';
import consentToCookies from './consentToCookies';
import signedIn from './signedIn';
import isHeroVisible from './isHeroVisible';

const rootReducer = combineReducers({
    showLeftMenu,
    activeAccount,
    darkMode,
    isConsideredMobile,
    isConsideredMedium,
    activeNetwork,
    btcPrice,
    consentToCookies,
    signedIn,
    isHeroVisible,
});

export default rootReducer;